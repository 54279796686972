import { LIST } from '@/constants/viewTypes';

import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  VAT: 'vat',
  AMOUNT: 'amount',
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  ROOM: 'room',
  IS_ONE_TIME: 'isOneTime',
  CLIENT: 'client',
  SERVICES: 'services',
  INVOICE_DATE_LAST: 'invoiceDateLast',
  INVOICE_DATE_FIRST: 'invoiceDateFirst',
};

/** @type {import('./schema').default}  */
export const schema = [
  {
    type: schemaItemTypes.STRING,
    label: 'invoice.invoice_number',
    prop: 'invoiceNumber',
    rules: [rules.REQUIRED],
    tooltip: { text: 'invoice.invoice_number_tooltip', icon: 'mdi-information' },
    payload: [
      {
        from: ['automaticInvoiceNumberingTooltip'],
        param: 'tooltip',
      },
      {
        from: ['automaticInvoiceNumbering'],
        param: 'disabled',
      },
    ],
  },
  {
    type: schemaItemTypes.DATE,
    label: 'invoice.date_first_invoice',
    prop: dependentProperties.INVOICE_DATE_FIRST,
    rules: [rules.REQUIRED],
    tooltip: { text: 'invoice.invoice_date_first_tooltip', icon: 'mdi-information' },
    onlyFutureDate: true,
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.INVOICE_DATE_LAST,
      },
    ],
  },
  {
    type: schemaItemTypes.ENUM,
    label: 'invoice.recurrence_period',
    prop: 'recurrencePeriod',
    rules: [rules.REQUIRED],
    dictionary: dictionaryTypes.RECURRENCE_PERIODS,
  },
  {
    type: schemaItemTypes.DATE,
    label: 'invoice.date_last_invoice',
    prop: dependentProperties.INVOICE_DATE_LAST,
    rules: [rules.REQUIRED],
    payload: [
      {
        param: 'minDate',
        from: [dependentProperties.INVOICE_DATE_FIRST],
        serializer: serializers.NEXT_DAY,
      },
    ],
  },

  {
    type: schemaItemTypes.CLIENT,
    label: 'invoice.select_client',
    prop: dependentProperties.CLIENT,
    rules: [rules.REQUIRED],
    payload: [
      { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
      { param: 'unitId', from: [dependentProperties.UNIT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.PROJECT,
      },
    ],
  },
  {
    type: schemaItemTypes.PROJECT,
    label: 'invoice.select_project',
    prop: dependentProperties.PROJECT,
    rules: [rules.REQUIRED],
    payload: [{ param: 'clientId', from: [dependentProperties.CLIENT, 'id'] }],
    onUpdate: [
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.BUILDING,
      },
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.CLIENT,
      },
    ],
  },
  {
    type: schemaItemTypes.BUILDING,
    label: 'invoice.select_building',
    prop: 'building',
    rules: [rules.REQUIRED],
    payload: [
      { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: dependentProperties.PROJECT,
        from: 'project',
      },
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.UNIT,
      },
    ],
  },
  {
    type: schemaItemTypes.UNIT,
    label: 'invoice.select_unit',
    prop: 'unit',
    rules: [rules.REQUIRED],
    payload: [
      { param: 'buildingId', from: [dependentProperties.BUILDING, 'id'] },
      { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
      { param: 'roomId', from: [dependentProperties.ROOM, 'id'] },
      { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: dependentProperties.BUILDING,
        from: 'building',
      },
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.ROOM,
      },
    ],
  },
  {
    type: schemaItemTypes.ROOM,
    label: 'label.room',
    prop: 'room',
    access: ['rooms'],
    payload: [
      { param: 'unitId', from: [dependentProperties.UNIT, 'id'] },
      { param: 'buildingId', from: [dependentProperties.BUILDING, 'id'] },
      { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: dependentProperties.UNIT,
        from: 'unit',
      },
    ],
  },
  {
    type: schemaItemTypes.CONTRACTOR,
    label: 'invoice.select_contractor',
    prop: 'contractor',
    rules: [rules.REQUIRED],
  },
  {
    label: 'invoice.vat_code',
    prop: 'vatCode',
    type: schemaItemTypes.STRING,
  },
  {
    label: 'invoice.department',
    prop: 'department',
    type: schemaItemTypes.STRING,
  },
  {
    type: schemaItemTypes.ENUM,
    label: 'invoice.service_type',
    prop: 'serviceType',
    dictionary: dictionaryTypes.SERVICE_TYPES,
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.LIST,
    prop: dependentProperties.SERVICES,
    uniqueKey: 'id',
    label: 'invoice.add_service_title',
    addButtonText: 'invoice.add_service',
    min: 1,
    viewType: LIST.counter,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'invoice.write_service',
        prop: 'service',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.NUMBER,
            label: 'invoice.write_amount',
            prop: dependentProperties.AMOUNT,
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.PERCENT,
            label: 'invoice.vat',
            prop: dependentProperties.VAT,
            rules: [rules.REQUIRED, rules.PERCENT],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.TOTAL_AMOUNT,
    label: 'invoice.total_amount',
    payload: [
      {
        param: 'services',
        from: [dependentProperties.SERVICES],
      },
      {
        param: 'currencySymbol',
        from: [dependentProperties.PROJECT, 'currency', 'symbol'],
      },
    ],
  },
];

export { schema as default };
